.App {
  text-align: center;
}
body {
  line-height: 1.5;
  padding: 8px;
  margin: 4px;
}
h1 {
  font-size: 5.25rem;
}
